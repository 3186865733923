import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Form, Row, Input, Label, FormFeedback, Alert } from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// import images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import CarouselPage from "./CarouselPage";

import logo from "../../assets/images/jobLogo.png"
import Loader from "pages/Loader";
import axios from "axios";

const Login2 = () => {

  const navigate = useNavigate()

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [isLoading, setLoading] = useState(false)
  const [error2, setError2] = useState(false)

  const [show_pass, setShowPass] = useState(true)

  //meta title
  document.title = "Login | JobFitMeter";

  useEffect(() => {
    if (localStorage.getItem("adminName") && localStorage.getItem("adminId")) {
      navigate("/")
    }
  }, [])


  const handleLogin = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()

    formData.append("email", email)
    formData.append("password", password)
    formData.append("type", "admin")

    try {
      const response = await axios.post('https://api.jobfitmeter.com/api/admin_login', formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        }
      })

      if (response.data.success == true) {
        localStorage.setItem("adminId", response.data.data[0].id)
        localStorage.setItem("adminName", response.data.data[0].company_name)

        if (localStorage.getItem("junkLink")) {
          navigate(`/candidate/${localStorage.getItem("junkLink")}`)
        } else {
          navigate("/")
        }

      } else {
        setError2(true)
      }
      setLoading(false)

    } catch (err) {
      setError2(true)
      console.log(err)
      setLoading(false)
    }
  }

  return (
    <React.Fragment>

      {isLoading && <Loader />}

      <div>
        {/* <Container fluid className="p-0 w-100"> */}
        <Row className="g-0">
          <CarouselPage />

          <Col xl={3}>
            <div className="auth-full-page-content p-md-5 p-4">
              <div className="w-100">
                <div className="d-flex flex-column h-100">
                  <div className="mb-4 mb-md-5">
                    <Link to="/" className="d-block auth-logo text-uppercase fs-1 fw-bold text-info">
                      Jobfitmeter
                    </Link>
                  </div>
                  <div className="my-auto">
                    <div>
                      <h5 className="text-info">Welcome Back !</h5>
                      <p className="text-muted">
                        Sign in to continue to Jobfitmeter
                      </p>
                    </div>

                    <div className="mt-4">
                      <Form className="form-horizontal"
                        onSubmit={handleLogin}
                      >

                        {error2 ? (
                          <Alert color="danger">
                            Username and password are invalid. Please enter
                            correct username and password
                          </Alert>
                        ) : null}
                        <div className="mb-3">
                          <Label className="form-label">Username</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            value={email}
                            type="email"
                            onChange={e => {
                              setEmail(e.target.value)
                              setError2(false)
                            }}
                          />
                        </div>

                        <div className="mb-3 position-relative">
                          {/* <div className="float-end">
                            <Link to="/auth-recoverpw-2" className="text-muted">Forgot password?</Link>
                          </div> */}
                          <Label className="form-label">Password</Label>
                          <Input
                            name="password"
                            type={show_pass ? "password" : "text"}
                            placeholder="Enter Password"
                            value={password}
                            onChange={e => {
                              setPassword(e.target.value)
                              setError2(false)
                            }}
                          />
                          <div className="bd-eye-pass" onClick={() => setShowPass(!show_pass)}>
                            <i className={show_pass ? "fa fa-eye" : "fa fa-eye-slash"}></i>
                          </div>
                        </div>

                        <div className="form-check">
                          <Input
                            type="checkbox"
                            className="form-check-input"
                            id="auth-remember-check"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="auth-remember-check"
                          >
                            Remember me
                          </label>
                        </div>

                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block "
                            type="submit"
                          >
                            Log In
                          </button>
                        </div>


                        <div className="mt-4 text-center">
                          <p className="mb-0">
                            Don&#39;t have an account ?{" "}
                            <a href="https://www.jobfitmeter.com/register" className="fw-medium text-info">
                              {" "}
                              Register now{" "}
                            </a>{" "}
                          </p>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {/* </Container> */}
      </div>
    </React.Fragment>
  );
};

export default Login2;
