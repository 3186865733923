import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>

            <Col md={8}>
              <span>{new Date().getFullYear()} © JobFitMeter.</span>
              <span className='px-2'>|</span>
              <a href="https://www.jobfitmeter.com/privacy-policy" target="_blank" rel="noreferrer" className='text-decoration-none text-blue'> Privacy Policy </a>
              <span className='px-2'>|</span>
              <a href="https://www.jobfitmeter.com/terms-and-conditions" target="_blank" rel="noreferrer" className='text-decoration-none text-blue'> Terms and Conditions </a>
            </Col>

            <Col md={4} className="text-end">
              <a href='https://www.thestaffguru.com/' target='_blank' rel="noreferrer" className='text-decoration-none text-dark'>A Product of Staffguru</a>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
