import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row, Card, CardBody } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "pages/Loader";
import axios from "axios";
import swal from "sweetalert";
import StarRating from "./StarRating";
import { allIndustries } from "./AllIndustries";

const EditCandidates = () => {
    const [fixedContent, setFixedContent] = useState(false)

    const [candidate_name, setcandidate_name] = useState('')
    const [industry, setindustry] = useState('')
    const [aadhar_no, setaadhar_no] = useState('')
    const [dob, setDob] = useState('')
    // =======================================================
    const [emojiRating1, setEmojiRating1] = useState(1);
    const [emojiRating2, setEmojiRating2] = useState(1);
    const [emojiRating3, setEmojiRating3] = useState(1);
    const [emojiRating4, setEmojiRating4] = useState(1);
    const [emojiRating5, setEmojiRating5] = useState(1);

    const [starRation1, setStarRation1] = useState('')
    const [starRation2, setStarRation2] = useState('')
    const [starRation3, setStarRation3] = useState('')
    const [starRation4, setStarRation4] = useState('')
    const [starRation5, setStarRation5] = useState('')
    const [starRation6, setStarRation6] = useState('')
    const [starRation7, setStarRation7] = useState('')

    const [radio1, setRadio1] = useState(null)
    const [radio2, setRadio2] = useState(null)
    const [radio3, setRadio3] = useState(null)
    const [radio4, setRadio4] = useState(null)
    const [radio5, setRadio5] = useState(null)
    const [radio6, setRadio6] = useState(null)
    // ==================================================

    const [reviews, setreviews] = useState('')
    const [company_id, setCompanyId] = useState('')

    const [isLoading, setIsLoading] = useState(false)

    const navigate = useNavigate()

    const { candidate_id } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0)

        const fetchData = async () => {
            setIsLoading(true)
            try {
                const response = await axios.get(`https://api.jobfitmeter.com/api/superadmin_view_candidates/${candidate_id}`, {
                    header: {
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                    }
                });

                setcandidate_name(response.data.data.candidate[0].candidate_name)
                setindustry(response.data.data.candidate[0].industry)
                setaadhar_no(response.data.data.candidate[0].aadhar_no)
                setDob(response.data.data.candidate[0].dateofbirth)

                setStarRation1(response.data.data.candidate[0].interview_rate)
                setStarRation2(response.data.data.candidate[0].communication_rate)
                setStarRation3(response.data.data.candidate[0].technical_rate)
                setStarRation4(response.data.data.candidate[0].onbording_experience)
                setStarRation5(response.data.data.candidate[0].integrate_rate)
                setStarRation6(response.data.data.candidate[0].adaptability_rate)
                setStarRation7(response.data.data.candidate[0].problem_resolution_rate)

                setEmojiRating1(response.data.data.candidate[0].cultural_fit)
                setEmojiRating2(response.data.data.candidate[0].productivity_rate)
                setEmojiRating3(response.data.data.candidate[0].collaboration_rate)
                setEmojiRating4(response.data.data.candidate[0].initiative_rate)
                setEmojiRating5(response.data.data.candidate[0].feedback_rate)

                setRadio1(response.data.data.candidate[0].inteview)
                setRadio2(response.data.data.candidate[0].problem_solving)
                setRadio3(response.data.data.candidate[0].critical_skill)
                setRadio4(response.data.data.candidate[0].timeliness_join)
                setRadio5(response.data.data.candidate[0].integrate_team)
                setRadio6(response.data.data.candidate[0].collaboration_team)

                setreviews(response.data.data.candidate[0].reviews)

                setCompanyId(response.data.data.candidate[0].company_id)

                setIsLoading(false)
            }
            catch (error) {
                console.log(error)
            }
        }
        fetchData()
    }, [])

    document.title = "Edit Candidate | JobFitMeter"

    document.addEventListener("scroll", () => {
        if (window.scrollY > 120) {
            setFixedContent(true)
        } else {
            setFixedContent(false)
        }
    })

    const handleEditCandidate = async (e) => {
        e.preventDefault()

        setIsLoading(true)
        try {
            const formData = new FormData()
            formData.append("candidate_name", candidate_name)
            formData.append("industry", industry)
            formData.append("aadhar_no", aadhar_no)
            formData.append("dateofbirth", dob)

            // star rating
            formData.append("interview_rate", starRation1)
            formData.append("communication_rate", starRation2)
            formData.append("technical_rate", starRation3)
            formData.append("onbording_experience", starRation4)
            formData.append("integrate_rate", starRation5)
            formData.append("adaptability_rate", starRation6)
            formData.append("problem_resolution_rate", starRation7)
            // emoji rating
            formData.append("cultural_fit", emojiRating1)
            formData.append("productivity_rate", emojiRating2)
            formData.append("collaboration_rate", emojiRating3)
            formData.append("initiative_rate", emojiRating4)
            formData.append("feedback_rate", emojiRating5)
            // radio input
            formData.append("inteview", radio1 == null ? 0 : radio1)
            formData.append("problem_solving", radio2 == null ? 0 : radio2)
            formData.append("critical_skill", radio3 == null ? 0 : radio3)
            formData.append("timeliness_join", radio4 == null ? 0 : radio4)
            formData.append("integrate_team", radio5 == null ? 0 : radio5)
            formData.append("collaboration_team", radio6 == null ? 0 : radio6)

            formData.append("reviews", reviews)

            formData.append("candidate_id", candidate_id)
            formData.append("company_id", company_id)

            const response = await axios.post('https://api.jobfitmeter.com/api/edit_candidates', formData, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                }
            })

            setIsLoading(false)

            swal("Review has been updated successfully!", {
                icon: "success",
            }).then((res) => {
                navigate('/view-candidates')
            })


        } catch (err) {
            console.log(err)
            setIsLoading(false)
            swal("Something Went Wrong", "", "error")
        }
    }

    return (
        <React.Fragment>

            {
                isLoading && <Loader />
            }

            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title={"Dashboard"}
                        breadcrumbItem={"Edit Candidate"}
                    />

                    <Row>
                        <form action="" className="px-0" onSubmit={handleEditCandidate}>
                            <Card className="px-1">
                                <CardBody>

                                    {/* fixed Buttons */}
                                    <div
                                        className={`flex-column fixed-buttons ${fixedContent ? "d-flex" : "d-none"
                                            }`}
                                    >
                                        <button type="submit" className="btn btn-primary btn-blue fix-btn">
                                            <i className="fas fa-location-arrow me-2"></i>
                                            <span>Submit</span>
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => navigate(-1)}
                                            className="btn btn-secondary bg-dark-blue fix-btn"
                                        >
                                            <i className="fas fa-arrow-left me-2"></i>
                                            <span>Back</span>
                                        </button>
                                    </div>
                                    <Row>
                                        <div className="col-12 mb-4 text-end">
                                            <button type="submit" className="btn btn-primary btn-blue me-3">
                                                <i className="fas fa-location-arrow me-2"></i>
                                                Submit
                                            </button>

                                            <button type="button" onClick={() => navigate(-1)} className="btn btn-secondary bg-dark-blue ">
                                                <i className="fas fa-arrow-left me-3"></i>
                                                Back
                                            </button>
                                        </div>

                                        <div className="col-md-3 col-sm-6 mb-md-2 mb-4">
                                            <label htmlFor="">Aadhar Number </label>
                                            <input type="text"
                                                disabled
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key))
                                                        event.preventDefault();
                                                }}
                                                maxLength={12}
                                                value={aadhar_no}
                                                onChange={(e) => setaadhar_no(e.target.value)}
                                                placeholder="Enter Aadhar Number" className="form-control py-2" />
                                        </div>

                                        <div className="col-md-3 col-sm-6 mb-md-2 mb-4">
                                            <label htmlFor="">Candidate Name <span className="text-danger">*</span>
                                            </label>
                                            <input type="text"
                                                disabled
                                                value={candidate_name}
                                                maxLength={12}
                                                onChange={(e) => setcandidate_name(e.target.value)}
                                                placeholder="Enter Candidate Name" className="form-control py-2" required />
                                        </div>

                                        <div className="col-md-3 col-sm-6 mb-md-2 mb-4">
                                            <label htmlFor="">Date of Birth <span className="text-danger">*</span> </label>
                                            <input type="date"
                                                value={dob}
                                                onChange={(e) => setDob(e.target.value)}
                                                placeholder="Enter Aadhar Number" className="form-control py-2" required disabled />
                                        </div>

                                        <div className="col-md-3 col-sm-6 mb-md-2 mb-4">
                                            <label htmlFor="">Industry <span className='text-danger'>*</span></label>

                                            <select
                                                disabled
                                                value={industry}
                                                onChange={(e) => setindustry(e.target.value)}
                                                className='form-select py-2' required>
                                                <option value="">--Choose Industry--</option>
                                                {
                                                    allIndustries.map((industry, i) => {
                                                        return (
                                                            <option key={i} value={industry}>{industry}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </Row>
                                </CardBody>
                            </Card>

                            <Card className="px-1">
                                <CardBody>
                                    <div className="row">
                                        <h5 className="mb-4 text-blue mt-2 card-title d-flex justify-content-between">
                                            Interview Feedback</h5>

                                        <div className="col-md-6 mb-md-5 mb-4">
                                            <h6 className="fw-bold mb-2 text-dark-blue">1. Punctuality and Professionalism</h6>

                                            <div className="ms-3">
                                                <label htmlFor="" className="d-flex"><span className="me-2">i. </span>
                                                    Did the candidate appear for the interview as scheduled</label>
                                                <div className="yesNo mb-3">
                                                    <input type="radio" name="schedule"
                                                        checked={radio1 === 1}
                                                        onClick={(e) => {
                                                            setRadio1(Number(e.target.value))
                                                        }}
                                                        value={1} className="me-2" />
                                                    <label htmlFor=""> Yes </label>

                                                    <input type="radio" name="schedule"
                                                        checked={radio1 === 0}
                                                        onClick={(e) => {
                                                            setRadio1(Number(e.target.value))
                                                        }}
                                                        value={0} className="ms-3 me-2" />
                                                    <label htmlFor=""> No </label>
                                                </div>

                                                <label htmlFor="" className="d-flex mb-0"><span className="me-2">ii. </span> How would you rate their punctuality and professionalism during the interview process?</label>

                                                <StarRating rating={starRation1} setRating={setStarRation1} />
                                            </div>
                                        </div>

                                        <div className="col-md-6 mb-md-5 mb-4">
                                            <h6 className="fw-bold mb-2 text-dark-blue">2. Communication Skills</h6>

                                            <div className="ms-3">

                                                <label htmlFor="" className="d-flex mb-0"><span className="me-2">i. </span> How effectively did the candidate communicate during the interview, including their ability to articulate thoughts, answer questions, and engage with the interviewers?</label>

                                                <StarRating rating={starRation2} setRating={setStarRation2} />
                                            </div>
                                        </div>

                                        <div className="col-md-6 mb-md-5 mb-4">
                                            <h6 className="fw-bold mb-2 text-dark-blue">3. Technical Competence</h6>

                                            <div className="ms-3">

                                                <label htmlFor="" className="d-flex mb-0"><span className="me-2">i. </span> How good was candidate's technical knowledge or skills, to support their qualifications?</label>

                                                <StarRating rating={starRation3} setRating={setStarRation3} />
                                            </div>
                                        </div>

                                        <div className="col-md-6 mb-md-5 mb-4">
                                            <h6 className="fw-bold mb-2 text-dark-blue">4. Problem-Solving and Critical Thinking</h6>

                                            <div className="ms-3">
                                                <label htmlFor="" className="d-flex"><span className="me-2">i. </span>
                                                    Did the candidate demonstrated problem-solving?</label>
                                                <div className="yesNo">
                                                    <input type="radio" name="demonstrated"
                                                        checked={radio2 === 1}
                                                        onClick={(e) => {
                                                            console.log(Number(e.target.value))
                                                            setRadio2(Number(e.target.value))
                                                        }}
                                                        value={1} className="me-2" />
                                                    <label htmlFor=""> Yes </label>

                                                    <input type="radio" name="demonstrated"
                                                        checked={radio2 === 0}
                                                        onClick={(e) => {
                                                            console.log(Number(e.target.value))
                                                            setRadio2(Number(e.target.value))
                                                        }}
                                                        value={0} className="ms-3 me-2" />
                                                    <label htmlFor=""> No </label>
                                                </div>

                                                <label htmlFor="" className="d-flex"><span className="me-2">ii. </span>
                                                    Critical thinking skills during the interview?</label>
                                                <div className="yesNo">
                                                    <input type="radio" name="Critical"
                                                        checked={radio3 === 1}
                                                        onClick={(e) => {
                                                            setRadio3(Number(e.target.value))
                                                        }}
                                                        value={1} className="me-2" />
                                                    <label htmlFor=""> Yes </label>

                                                    <input type="radio" name="Critical"
                                                        checked={radio3 === 0}
                                                        onClick={(e) => {
                                                            setRadio3(Number(e.target.value))
                                                        }}
                                                        value={0} className="ms-3 me-2" />
                                                    <label htmlFor=""> No </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6 mb-3">
                                            <h6 className="fw-bold mb-2 text-dark-blue">5. Cultural Fit</h6>

                                            <div className="ms-3">

                                                <label htmlFor="" className="d-flex mb-0"><span className="me-2">i. </span> Based on the interview, how well does the candidate seem to align with our company culture, values, and team dynamics?</label>

                                                {/* Rate Creative */}
                                                <div className="d-flex align-items-center">
                                                    <input type="range"
                                                        value={emojiRating1}
                                                        onChange={(e) => setEmojiRating1(e.target.value)}
                                                        min={1} max={5} />
                                                    <div className="fs-2 ms-3">
                                                        {emojiRating1 == 1 && "😞"}
                                                        {emojiRating1 == 2 && "🙁"}
                                                        {emojiRating1 == 3 && "😐"}
                                                        {emojiRating1 == 4 && "🙂"}
                                                        {emojiRating1 == 5 && "😀"}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>

                            <Card className="px-1">
                                <CardBody>
                                    <div className="row">
                                        <h5 className="mb-4 text-blue mt-2 card-title d-flex justify-content-between">
                                            Onboarding Feedback</h5>

                                        <div className="col-md-6 mb-md-5 mb-4">
                                            <h6 className="fw-bold mb-2 text-dark-blue">1. Timeliness of Joining</h6>

                                            <div className="ms-3">
                                                <label htmlFor="" className="d-flex"><span className="me-2">i. </span>
                                                    Did the candidate join the company as per the agreed-upon ?</label>
                                                <div className="yesNo ">
                                                    <input type="radio" name="agreed-upon"
                                                        checked={radio4 === 1}
                                                        onClick={(e) => {
                                                            setRadio4(Number(e.target.value))
                                                        }}
                                                        value={1} className="me-2" />
                                                    <label htmlFor=""> Yes </label>

                                                    <input type="radio" name="agreed-upon"
                                                        checked={radio4 === 0}
                                                        onClick={(e) => {
                                                            setRadio4(Number(e.target.value))
                                                        }}
                                                        value={0} className="ms-3 me-2" />
                                                    <label htmlFor=""> No </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6 mb-md-5 mb-4">
                                            <h6 className="fw-bold mb-2 text-dark-blue">2. Onboarding Experience</h6>

                                            <div className="ms-3">
                                                <label htmlFor="" className="d-flex mb-0"><span className="me-2">i. </span> How would the candidate rate their onboarding experience, including the clarity of information, training, and support provided?</label>

                                                <StarRating rating={starRation4} setRating={setStarRation4} />
                                            </div>
                                        </div>

                                        <div className="col-md-6 mb-3">
                                            <h6 className="fw-bold mb-2 text-dark-blue">3. Integration into the Team</h6>

                                            <div className="ms-3">
                                                <label htmlFor="" className="d-flex"><span className="me-2">i. </span>
                                                    Did the candidate easily integrate into their new team ?</label>
                                                <div className="yesNo mb-3">
                                                    <input type="radio" name="integrate"
                                                        checked={radio5 === 1}
                                                        onClick={(e) => {
                                                            setRadio5(Number(e.target.value))
                                                        }}
                                                        value={1} className="me-2" />
                                                    <label htmlFor=""> Yes </label>

                                                    <input type="radio" name="integrate"
                                                        checked={radio5 === 0}
                                                        onClick={(e) => {
                                                            setRadio5(Number(e.target.value))
                                                        }}
                                                        value={0} className="ms-3 me-2" />
                                                    <label htmlFor=""> No </label>
                                                </div>

                                                <label htmlFor="" className="d-flex mb-0"><span className="me-2">ii. </span> Rate did they actively seek to build relationships with colleagues and managers? </label>

                                                <StarRating rating={starRation5} setRating={setStarRation5} />
                                            </div>
                                        </div>

                                        <div className="col-md-6 mb-3">
                                            <h6 className="fw-bold mb-2 text-dark-blue">4. Adaptability</h6>

                                            <div className="ms-3">

                                                <label htmlFor="" className="d-flex mb-0"><span className="me-2">i. </span> Candidate adapted to the new role and company environment during the onboarding process? </label>

                                                <StarRating rating={starRation6} setRating={setStarRation6} />
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>

                            <Card className="px-1">
                                <CardBody>
                                    <Row>
                                        <h5 className="mb-4 text-blue mt-2 card-title d-flex justify-content-between">
                                            Behavioural Feedback after Joining</h5>

                                        <div className="col-md-6 mb-md-5 mb-4">
                                            <h6 className="fw-bold mb-2 text-dark-blue">1. Work Ethic and Productivity</h6>

                                            <div className="ms-3">
                                                <label htmlFor="" className="d-flex"><span className="me-2">i. </span>
                                                    How would you describe the candidate's work ethic and productivity since they joined the company ?</label>

                                                {/* Rate Creative */}
                                                <div className="d-flex align-items-center">
                                                    <input type="range"
                                                        value={emojiRating2}
                                                        onChange={(e) => setEmojiRating2(e.target.value)}
                                                        min={1} max={5} />
                                                    <div className="fs-2 ms-3">
                                                        {emojiRating2 == 1 && "😞"}
                                                        {emojiRating2 == 2 && "🙁"}
                                                        {emojiRating2 == 3 && "😐"}
                                                        {emojiRating2 == 4 && "🙂"}
                                                        {emojiRating2 == 5 && "😀"}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6 mb-md-5 mb-4">
                                            <h6 className="fw-bold mb-2 text-dark-blue">2. Team Collaboration</h6>

                                            <div className="ms-3">
                                                <label htmlFor="" className="d-flex"><span className="me-2">i. </span>
                                                    Has the candidate been proactive in collaborating with their team members ?</label>
                                                <div className="yesNo mb-3">
                                                    <input type="radio" name="proactive"
                                                        checked={radio6 === 1}
                                                        onClick={(e) => {
                                                            setRadio6(Number(e.target.value))
                                                        }}
                                                        value={1} className="me-2" />
                                                    <label htmlFor=""> Yes </label>

                                                    <input type="radio" name="proactive"
                                                        checked={radio6 === 0}
                                                        onClick={(e) => {
                                                            setRadio6(Number(e.target.value))
                                                        }}
                                                        value={0} className="ms-3 me-2" />
                                                    <label htmlFor=""> No </label>
                                                </div>

                                                <label htmlFor="" className="d-flex mb-0"><span className="me-2">ii. </span> Rate their contribute to the overall team dynamic?</label>
                                                {/* Rate Creative */}
                                                <div className="d-flex align-items-center">
                                                    <input type="range"
                                                        value={emojiRating3}
                                                        onChange={(e) => setEmojiRating3(e.target.value)}
                                                        min={1} max={5} />
                                                    <div className="fs-2 ms-3">
                                                        {emojiRating3 == 1 && "😞"}
                                                        {emojiRating3 == 2 && "🙁"}
                                                        {emojiRating3 == 3 && "😐"}
                                                        {emojiRating3 == 4 && "🙂"}
                                                        {emojiRating3 == 5 && "😀"}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6 mb-md-5 mb-4">
                                            <h6 className="fw-bold mb-2 text-dark-blue">3. Problem Resolution</h6>

                                            <div className="ms-3">

                                                <label htmlFor="" className="d-flex mb-0"><span className="me-2">i. </span> Were the candidate able to effectively resolved workplace challenges or conflicts? </label>

                                                <StarRating rating={starRation7} setRating={setStarRation7} />
                                            </div>
                                        </div>

                                        <div className="col-md-6 mb-md-5 mb-4">
                                            <h6 className="fw-bold mb-2 text-dark-blue">4. Initiative and Growth</h6>

                                            <div className="ms-3">

                                                <label htmlFor="" className="d-flex mb-0"><span className="me-2">i. </span> Has the candidate demonstrated a willingness to take initiative and further their professional development within the organisation? </label>
                                                {/* Rate Creative */}
                                                <div className="d-flex align-items-center">
                                                    <input type="range"
                                                        value={emojiRating4}
                                                        onChange={(e) => setEmojiRating4(e.target.value)}
                                                        min={1} max={5} />
                                                    <div className="fs-2 ms-3">
                                                        {emojiRating4 == 1 && "😞"}
                                                        {emojiRating4 == 2 && "🙁"}
                                                        {emojiRating4 == 3 && "😐"}
                                                        {emojiRating4 == 4 && "🙂"}
                                                        {emojiRating4 == 5 && "😀"}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6 mb-md-5 mb-4">
                                            <h6 className="fw-bold mb-2 text-dark-blue">5. Feedback Reception</h6>

                                            <div className="ms-3">

                                                <label htmlFor="" className="d-flex mb-0"><span className="me-2">i. </span> How open has the candidate been to receiving feedback and making necessary improvements in their role and behaviour within the company? </label>
                                                {/* Rate Creative */}
                                                <div className="d-flex align-items-center">
                                                    <input type="range"
                                                        value={emojiRating5}
                                                        onChange={(e) => setEmojiRating5(e.target.value)}
                                                        min={1} max={5} />
                                                    <div className="fs-2 ms-3">
                                                        {emojiRating5 == 1 && "😞"}
                                                        {emojiRating5 == 2 && "🙁"}
                                                        {emojiRating5 == 3 && "😐"}
                                                        {emojiRating5 == 4 && "🙂"}
                                                        {emojiRating5 == 5 && "😀"}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* review textarea */}

                                        <div className="col-12 mb-3">
                                            <label htmlFor="">Add Your Review <span className="text-danger">*</span> </label>
                                            <textarea rows="5"
                                                value={reviews}
                                                onChange={(e) => setreviews(e.target.value)}
                                                placeholder="Add Your Review" className="form-control py-2" required></textarea>
                                        </div>
                                    </Row>
                                </CardBody>
                            </Card>
                        </form>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};


export default EditCandidates;
