import React, { useEffect, useState } from "react";
import { AccordionBody, AccordionHeader, AccordionItem, Col, Container, Row, UncontrolledAccordion } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card } from "reactstrap";

import { CardBody } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import axios from "axios";
import Loader from "pages/Loader";
import moment from "moment";

const Dashboard2 = props => {
  const [aadharNo, setAadharNo] = useState('');
  const [candidate, setCandidate] = useState(null);
  const [loading, setLoading] = useState(false)

  const [searchType, setSearchType] = useState("aadhar")
  const [searchName, setSearchName] = useState('')
  const [searchDob, setSearchDob] = useState('')

  const [isLoading, setIsLoading] = useState(false)

  const [candidateCount, setCandidateCount] = useState(0)

  // for average rating
  const [avgEmojiRating1, setAvgEmojiRating1] = useState(0);
  const [avgEmojiRating2, setAvgEmojiRating2] = useState(0);
  const [avgEmojiRating3, setAvgEmojiRating3] = useState(0);
  const [avgEmojiRating4, setAvgEmojiRating4] = useState(0);
  const [avgEmojiRating5, setAvgEmojiRating5] = useState(0);

  const [avgStarRation1, setAvgStarRation1] = useState(0)
  const [avgStarRation2, setAvgStarRation2] = useState(0)
  const [avgStarRation3, setAvgStarRation3] = useState(0)
  const [avgStarRation4, setAvgStarRation4] = useState(0)
  const [avgStarRation5, setAvgStarRation5] = useState(0)
  const [avgStarRation6, setAvgStarRation6] = useState(0)
  const [avgStarRation7, setAvgStarRation7] = useState(0)

  // for yes no
  const [yesCount1, setYesCount1] = useState(0)
  const [noCount1, setNoCount1] = useState(0)

  const [yesCount2, setYesCount2] = useState(0)
  const [noCount2, setNoCount2] = useState(0)

  const [yesCount3, setYesCount3] = useState(0)
  const [noCount3, setNoCount3] = useState(0)

  const [yesCount4, setYesCount4] = useState(0)
  const [noCount4, setNoCount4] = useState(0)

  const [yesCount5, setYesCount5] = useState(0)
  const [noCount5, setNoCount5] = useState(0)

  const [yesCount6, setYesCount6] = useState(0)
  const [noCount6, setNoCount6] = useState(0)


  //meta title
  document.title = "Dashboard | JobFitMeter ";

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {

        const formData = new FormData()

        formData.append("company_id", localStorage.getItem("adminId"))
        const response = await axios.post('https://api.jobfitmeter.com/api/view_candidates', formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          }
        })

        setCandidateCount(response.data.data.candidate_count)
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        console.log(error)
      }
    }

    fetchData()
  }, [])

  // search Candidate 
  const searchCandidate = () => {
    setLoading(true)
    setCandidate(null)

    const formData = new FormData();
    formData.append("aadhar_no", aadharNo)
    formData.append("candidate_name", searchName)
    formData.append("dateofbirth", searchDob)

    axios.post('https://api.jobfitmeter.com/api/filter_data_company', formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      }
    })
      .then((response) => {
        setCandidate(response.data.data)

        // console.log(response.data)

        let totalEmojiRating1 = 0
        let totalEmojiRating2 = 0
        let totalEmojiRating3 = 0
        let totalEmojiRating4 = 0
        let totalEmojiRating5 = 0
        let totalStarRation1 = 0
        let totalStarRation2 = 0
        let totalStarRation3 = 0
        let totalStarRation4 = 0
        let totalStarRation5 = 0
        let totalStarRation6 = 0
        let totalStarRation7 = 0

        // for yesno count
        let yesTotal1 = 0
        let noTotal1 = 0
        let yesTotal2 = 0
        let noTotal2 = 0
        let yesTotal3 = 0
        let noTotal3 = 0
        let yesTotal4 = 0
        let noTotal4 = 0
        let yesTotal5 = 0
        let noTotal5 = 0
        let yesTotal6 = 0
        let noTotal6 = 0

        response.data.data.forEach(candidate => {
          // for average
          totalEmojiRating1 += candidate.cultural_fit
          totalEmojiRating2 += candidate.productivity_rate
          totalEmojiRating3 += candidate.collaboration_rate
          totalEmojiRating4 += candidate.initiative_rate
          totalEmojiRating5 += candidate.feedback_rate

          totalStarRation1 += candidate.interview_rate
          totalStarRation2 += candidate.communication_rate
          totalStarRation3 += candidate.technical_rate
          totalStarRation4 += candidate.onbording_experience
          totalStarRation5 += candidate.integrate_rate
          totalStarRation6 += candidate.adaptability_rate
          totalStarRation7 += candidate.problem_resolution_rate

          candidate.inteview === 1 ? yesTotal1++ : noTotal1++
          candidate.problem_solving === 1 ? yesTotal2++ : noTotal2++
          candidate.critical_skill === 1 ? yesTotal3++ : noTotal3++
          candidate.timeliness_join === 1 ? yesTotal4++ : noTotal4++
          candidate.integrate_team === 1 ? yesTotal5++ : noTotal5++
          candidate.collaboration_team === 1 ? yesTotal6++ : noTotal6++

          // for yes no
        });

        setAvgEmojiRating1(Math.floor(totalEmojiRating1 / response.data.data.length))
        setAvgEmojiRating2(Math.floor(totalEmojiRating2 / response.data.data.length))
        setAvgEmojiRating3(Math.floor(totalEmojiRating3 / response.data.data.length))
        setAvgEmojiRating4(Math.floor(totalEmojiRating4 / response.data.data.length))
        setAvgEmojiRating5(Math.floor(totalEmojiRating5 / response.data.data.length))

        setAvgStarRation1(Math.floor(totalStarRation1 / response.data.data.length))
        setAvgStarRation2(Math.floor(totalStarRation2 / response.data.data.length))
        setAvgStarRation3(Math.floor(totalStarRation3 / response.data.data.length))
        setAvgStarRation4(Math.floor(totalStarRation4 / response.data.data.length))
        setAvgStarRation5(Math.floor(totalStarRation5 / response.data.data.length))
        setAvgStarRation6(Math.floor(totalStarRation6 / response.data.data.length))
        setAvgStarRation7(Math.floor(totalStarRation7 / response.data.data.length))

        setYesCount1(yesTotal1)
        setNoCount1(noTotal1)
        setYesCount2(yesTotal2)
        setNoCount2(noTotal2)
        setYesCount3(yesTotal3)
        setNoCount3(noTotal3)
        setYesCount4(yesTotal4)
        setNoCount4(noTotal4)
        setYesCount5(yesTotal5)
        setNoCount5(noTotal5)
        setYesCount6(yesTotal6)
        setNoCount6(noTotal6)

        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
        swal("Something Went Wrong", "", "error")
      })
  }

  // JUST TO SHOW RATING
  const ViewRatingStars = ({ rating_no }) => {
    return (
      <div>
        {
          [...Array(5)].map((star, index) => {
            index += 1;
            return (
              <button
                type="button"
                key={index}
                className={`starBtn ${index <= rating_no ? "on" : "off"}`}
              >
                <span className="star">&#9733;</span>
              </button>
            );
          })
        }
      </div>
    )
  }

  // ============================================================================================

  // ===============================================================================================

  return (
    <React.Fragment>
      <div className="page-content">
        {
          isLoading && <Loader />
        }

        <Container fluid className="px-0">

          {/* <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          /> */}

          <Row className="">
            <Col xl="4" className=" mb-4" >
              <div className=" p-4 bg-white candidate-card rounded">
                <i className="fas fa-users "></i>
                <h5 className="mb-0 mt-3 d-flex justify-content-between">
                  Total Candidates
                  <span>{candidateCount}</span>
                </h5>
              </div>
            </Col>
            <Col xl="4" className=" mb-4" >
              <div className=" p-4 bg-white candidate-card rounded">
                <Link to="/add-new-candidate" className="d-block">
                  <i className="fas fa-user-plus"></i>
                  <h5 className="mb-0 mt-3">Add Review</h5>
                </Link>
              </div>
            </Col>
            <Col xl="4" className=" mb-4" >
              <div className=" p-4 bg-white candidate-card rounded">
                <Link to="/view-candidates" className="d-block">
                  <i className="fas fa-eye "></i>
                  <h5 className="mb-0 mt-3">View Candidate</h5>
                </Link>
              </div>
            </Col>

            <Col md="12">
              {/* Search candidate with aadhar */}
              <Card>
                <CardBody>
                  <Row>
                    <Col md="7">
                      <div>
                        <div className="d-flex">
                          <h5 className="mb-3 card-title text-dark-blue">
                            Search Candidate With
                          </h5>
                          <div className="ms-4">
                            <input type="radio" id="aadhar" checked={searchType === "aadhar"}
                              onClick={(e) => {
                                setSearchName("")
                                setSearchDob("")
                                setSearchType(e.target.value)
                              }} name="decide" value="aadhar" />
                            <label htmlFor="aadhar" className="ps-2 pe-3" >Aadhar No.</label>

                            <input type="radio" id="name" checked={searchType === "name"} onClick={(e) => {
                              setAadharNo('')
                              setSearchType(e.target.value)
                            }} name="decide" value="name" />
                            <label htmlFor="name" className="ps-2" >Name & DOB</label>
                          </div>
                        </div>

                        <Row className="align-items-end">
                          <div className={`col-md-${searchType === "aadhar" ? '6' : '8'}`}>
                            {/*  */}

                            {
                              searchType === "aadhar" ?
                                <input type="number"
                                  placeholder="Enter Aadhar Number *"
                                  value={aadharNo}
                                  onChange={(e) => setAadharNo(e.target.value)}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key))
                                      event.preventDefault();
                                  }}
                                  className="form-control" />
                                :
                                <Row>
                                  <div className="col-8">
                                    <input type="text"
                                      placeholder="Enter Name *"
                                      value={searchName}
                                      onChange={(e) => setSearchName(e.target.value)}
                                      className="form-control" />
                                  </div>
                                  <div className="col-4">
                                    <input type="date"
                                      value={searchDob}
                                      onChange={(e) => setSearchDob(e.target.value)}
                                      className="form-control" />
                                  </div>
                                </Row>
                            }


                          </div>
                          <div className="col-md-4 mt-md-0 mt-3">
                            <button onClick={searchCandidate} className="btn btn-primary btn-blue me-3">
                              <i className={` me-2 ${loading ? 'fas fa-sync-alt loadingCandidate' : 'fas fa-search'}`}></i>
                              Search
                            </button>
                            <button className="btn btn-secondary"
                              onClick={() => {
                                setAadharNo('')
                                setCandidate(null)
                              }}
                            >
                              <i className="fas fa-undo-alt me-2"></i>
                              Reset
                            </button>
                          </div>
                        </Row>
                      </div>
                    </Col>
                  </Row>

                  <Row >
                    <Col md={12}>
                      <div>
                        <div className="mt-4">
                          <h5 className="mb-4 card-title text-dark-blue">Search Results </h5>
                          {
                            candidate === null ? <span className="text-secondary">Candidate Details will be appear here..</span> :
                              (
                                candidate?.length > 0 ?

                                  <Row className="bg-info bg-soft rounded mx-0 mt-4 px-md-2 pt-3">
                                    <div className="px-md-3 mt-2">

                                      <div className="row">
                                        <div className="col-md-3 mb-4">
                                          <label htmlFor="">Aadhar Number </label>
                                          <div className="view-field bg-white">{candidate[0].aadhar_no}</div>
                                        </div>

                                        <div className="col-md-3 mb-4">
                                          <label htmlFor="">Candidate Name
                                          </label>
                                          <div className="view-field bg-white">{candidate[0].candidate_name}</div>
                                        </div>

                                        <div className="col-md-3 mb-4">
                                          <label htmlFor="">Date of Birth
                                          </label>
                                          <div className="view-field bg-white">{moment(candidate[0].dateofbirth).format("DD-MM-YYYY")}</div>
                                        </div>

                                        <div className="col-md-3 mb-4">
                                          <label htmlFor="">Industry </label>

                                          <div className="view-field bg-white">{candidate[0].industry}</div>
                                        </div>

                                        <div className="col-12">
                                          <Card className="px-md-1">
                                            <CardBody className="px-md-4 px-2">
                                              <div className="row">
                                                <h5 className="mb-4 text-blue mt-2 card-title d-flex justify-content-between">
                                                  Interview Feedback</h5>

                                                <div className="col-md-6 mb-md-5 mb-4">
                                                  <h6 className="fw-bold mb-2 text-dark-blue">1. Punctuality and Professionalism</h6>

                                                  <div className="ms-3">
                                                    <label htmlFor="" className="d-flex"><span className="me-2">i. </span>
                                                      Did the candidate appear for the interview as scheduled</label>
                                                    <div className="yesNo mb-3">
                                                      <div className={`yesNoCard bg-soft mx-1 bg-success`}>
                                                        Yes {" : " + yesCount1}
                                                      </div>
                                                      <div className={`yesNoCard bg-soft mx-1 bg-danger`}>
                                                        No {" : " + noCount1}
                                                      </div>
                                                    </div>

                                                    <label htmlFor="" className="d-flex mb-0"><span className="me-2">ii. </span> How would you rate their punctuality and professionalism during the interview process?</label>

                                                    <ViewRatingStars rating_no={avgStarRation1} />
                                                  </div>
                                                </div>

                                                <div className="col-md-6 mb-md-5 mb-4">
                                                  <h6 className="fw-bold mb-2 text-dark-blue">2. Communication Skills</h6>

                                                  <div className="ms-3">

                                                    <label htmlFor="" className="d-flex mb-0"><span className="me-2">i. </span> How effectively did the candidate communicate during the interview, including their ability to articulate thoughts, answer questions, and engage with the interviewers?</label>

                                                    <ViewRatingStars rating_no={avgStarRation2} />
                                                  </div>
                                                </div>

                                                <div className="col-md-6 mb-md-5 mb-4">
                                                  <h6 className="fw-bold mb-2 text-dark-blue">3. Technical Competence</h6>

                                                  <div className="ms-3">

                                                    <label htmlFor="" className="d-flex mb-0"><span className="me-2">i. </span> How good was candidate's technical knowledge or skills, to support their qualifications?</label>

                                                    <ViewRatingStars rating_no={avgStarRation3} />
                                                  </div>
                                                </div>

                                                <div className="col-md-6 mb-md-5 mb-4">
                                                  <h6 className="fw-bold mb-2 text-dark-blue">4. Problem-Solving and Critical Thinking</h6>

                                                  <div className="ms-3">
                                                    <label htmlFor="" className="d-flex"><span className="me-2">i. </span>
                                                      Did the candidate demonstrated problem-solving?</label>
                                                    <div className="yesNo mb-3">
                                                      <div className={`yesNoCard bg-soft mx-1 bg-success`}>
                                                        Yes {" : " + yesCount2}
                                                      </div>
                                                      <div className={`yesNoCard bg-soft mx-1 bg-danger`}>
                                                        No {" : " + noCount2}
                                                      </div>

                                                    </div>

                                                    <label htmlFor="" className="d-flex"><span className="me-2">ii. </span>
                                                      Critical thinking skills during the interview?</label>
                                                    <div className="yesNo">
                                                      <div className={`yesNoCard bg-soft mx-1 bg-success`}>
                                                        Yes {" : " + yesCount3}
                                                      </div>
                                                      <div className={`yesNoCard bg-soft mx-1 bg-danger`}>
                                                        No {" : " + noCount3}
                                                      </div>

                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="col-md-6 ">
                                                  <h6 className="fw-bold mb-2 text-dark-blue">5. Cultural Fit</h6>

                                                  <div className="ms-3">

                                                    <label htmlFor="" className="d-flex mb-0"><span className="me-2">i. </span> Based on the interview, how well does the candidate seem to align with our company culture, values, and team dynamics?</label>

                                                    {/* Rate Creative */}
                                                    <div className="d-flex align-items-center">
                                                      <input type="range"
                                                        disabled
                                                        value={avgEmojiRating1}
                                                        min={1} max={5} />
                                                      <div className="fs-2 ms-3">
                                                        {avgEmojiRating1 == 1 && "😞"}
                                                        {avgEmojiRating1 == 2 && "🙁"}
                                                        {avgEmojiRating1 == 3 && "😐"}
                                                        {avgEmojiRating1 == 4 && "🙂"}
                                                        {avgEmojiRating1 == 5 && "😀"}

                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </CardBody>
                                          </Card>

                                          <Card className="px-md-1">
                                            <CardBody className="px-md-4 px-2">
                                              <div className="row">
                                                <h5 className="mb-4 text-blue mt-2 card-title d-flex justify-content-between">
                                                  Onboarding Feedback</h5>

                                                <div className="col-md-6 mb-md-5 mb-4">
                                                  <h6 className="fw-bold mb-2 text-dark-blue">1. Timeliness of Joining</h6>

                                                  <div className="ms-3">
                                                    <label htmlFor="" className="d-flex"><span className="me-2">i. </span>
                                                      Did the candidate join the company as per the agreed-upon ?</label>
                                                    <div className="yesNo">
                                                      <div className={`yesNoCard bg-soft mx-1 bg-success`}>
                                                        Yes {" : " + yesCount4}
                                                      </div>
                                                      <div className={`yesNoCard bg-soft mx-1 bg-danger`}>
                                                        No {" : " + noCount4}
                                                      </div>

                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="col-md-6 mb-md-5 mb-4">
                                                  <h6 className="fw-bold mb-2 text-dark-blue">2. Onboarding Experience</h6>

                                                  <div className="ms-3">
                                                    <label htmlFor="" className="d-flex mb-0"><span className="me-2">i. </span> How would the candidate rate their onboarding experience, including the clarity of information, training, and support provided?</label>

                                                    <ViewRatingStars rating_no={avgStarRation4} />
                                                  </div>
                                                </div>

                                                <div className="col-md-6">
                                                  <h6 className="fw-bold mb-2 text-dark-blue">3. Integration into the Team</h6>

                                                  <div className="ms-3">
                                                    <label htmlFor="" className="d-flex"><span className="me-2">i. </span>
                                                      Did the candidate easily integrate into their new team ?</label>
                                                    <div className="yesNo mb-3">
                                                      <div className={`yesNoCard bg-soft mx-1 bg-success`}>
                                                        Yes {" : " + yesCount5}
                                                      </div>
                                                      <div className={`yesNoCard bg-soft mx-1 bg-danger`}>
                                                        No {" : " + noCount5}
                                                      </div>
                                                    </div>

                                                    <label htmlFor="" className="d-flex mb-0"><span className="me-2">ii. </span> Rate did they actively seek to build relationships with colleagues and managers? </label>

                                                    <ViewRatingStars rating_no={avgStarRation5} />
                                                  </div>
                                                </div>

                                                <div className="col-md-6">
                                                  <h6 className="fw-bold mb-2 text-dark-blue">4. Adaptability</h6>

                                                  <div className="ms-3">

                                                    <label htmlFor="" className="d-flex mb-0"><span className="me-2">i. </span> Candidate adapted to the new role and company environment during the onboarding process? </label>

                                                    <ViewRatingStars rating_no={avgStarRation6} />
                                                  </div>
                                                </div>
                                              </div>
                                            </CardBody>
                                          </Card>

                                          <Card className="px-md-1">
                                            <CardBody className="px-md-4 px-2">
                                              <Row>
                                                <h5 className="mb-4 text-blue mt-2 card-title d-flex justify-content-between">
                                                  Behavioural Feedback after Joining</h5>

                                                <div className="col-md-6 mb-md-5 mb-4">
                                                  <h6 className="fw-bold mb-2 text-dark-blue">1. Work Ethic and Productivity</h6>

                                                  <div className="ms-3">
                                                    <label htmlFor="" className="d-flex"><span className="me-2">i. </span>
                                                      How would you describe the candidate's work ethic and productivity since they joined the company ?</label>

                                                    {/* Rate Creative */}
                                                    <div className="d-flex align-items-center">
                                                      <input type="range"
                                                        disabled
                                                        value={avgEmojiRating2}
                                                        min={1} max={5} />
                                                      <div className="fs-2 ms-3">
                                                        {avgEmojiRating2 == 1 && "😞"}
                                                        {avgEmojiRating2 == 2 && "🙁"}
                                                        {avgEmojiRating2 == 3 && "😐"}
                                                        {avgEmojiRating2 == 4 && "🙂"}
                                                        {avgEmojiRating2 == 5 && "😀"}

                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="col-md-6 mb-md-5 mb-4">
                                                  <h6 className="fw-bold mb-2 text-dark-blue">2. Team Collaboration</h6>

                                                  <div className="ms-3">
                                                    <label htmlFor="" className="d-flex"><span className="me-2">i. </span>
                                                      Has the candidate been proactive in collaborating with their team members ?</label>
                                                    <div className="yesNo mb-3">
                                                      <div className={`yesNoCard bg-soft mx-1 bg-success`}>
                                                        Yes {" : " + yesCount6}
                                                      </div>
                                                      <div className={`yesNoCard bg-soft mx-1 bg-danger`}>
                                                        No {" : " + noCount6}
                                                      </div>
                                                    </div>

                                                    <label htmlFor="" className="d-flex mb-0"><span className="me-2">ii. </span> Rate their contribute to the overall team dynamic?</label>
                                                    {/* Rate Creative */}
                                                    <div className="d-flex align-items-center">
                                                      <input type="range"
                                                        disabled
                                                        value={avgEmojiRating3}
                                                        min={1} max={5} />
                                                      <div className="fs-2 ms-3">
                                                        {avgEmojiRating3 == 1 && "😞"}
                                                        {avgEmojiRating3 == 2 && "🙁"}
                                                        {avgEmojiRating3 == 3 && "😐"}
                                                        {avgEmojiRating3 == 4 && "🙂"}
                                                        {avgEmojiRating3 == 5 && "😀"}

                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="col-md-6 mb-md-5 mb-4">
                                                  <h6 className="fw-bold mb-2 text-dark-blue">3. Problem Resolution</h6>

                                                  <div className="ms-3">

                                                    <label htmlFor="" className="d-flex mb-0"><span className="me-2">i. </span> Were the candidate able to effectively resolved workplace challenges or conflicts? </label>

                                                    <ViewRatingStars rating_no={avgStarRation7} />
                                                  </div>
                                                </div>

                                                <div className="col-md-6 mb-md-5 mb-4">
                                                  <h6 className="fw-bold mb-2 text-dark-blue">4. Initiative and Growth</h6>

                                                  <div className="ms-3">

                                                    <label htmlFor="" className="d-flex mb-0"><span className="me-2">i. </span> Has the candidate demonstrated a willingness to take initiative and further their professional development within the organisation? </label>
                                                    {/* Rate Creative */}
                                                    <div className="d-flex align-items-center">
                                                      <input type="range"
                                                        disabled
                                                        value={avgEmojiRating4}
                                                        min={1} max={5} />
                                                      <div className="fs-2 ms-3">
                                                        {avgEmojiRating4 == 1 && "😞"}
                                                        {avgEmojiRating4 == 2 && "🙁"}
                                                        {avgEmojiRating4 == 3 && "😐"}
                                                        {avgEmojiRating4 == 4 && "🙂"}
                                                        {avgEmojiRating4 == 5 && "😀"}

                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="col-md-6 mb-md-5 mb-4">
                                                  <h6 className="fw-bold mb-2 text-dark-blue">5. Feedback Reception</h6>

                                                  <div className="ms-3">

                                                    <label htmlFor="" className="d-flex mb-0"><span className="me-2">i. </span> How open has the candidate been to receiving feedback and making necessary improvements in their role and behaviour within the company? </label>
                                                    {/* Rate Creative */}
                                                    <div className="d-flex align-items-center">
                                                      <input type="range"
                                                        disabled
                                                        value={avgEmojiRating5}
                                                        min={1} max={5} />
                                                      <div className="fs-2 ms-3">
                                                        {avgEmojiRating5 == 1 && "😞"}
                                                        {avgEmojiRating5 == 2 && "🙁"}
                                                        {avgEmojiRating5 == 3 && "😐"}
                                                        {avgEmojiRating5 == 4 && "🙂"}
                                                        {avgEmojiRating5 == 5 && "😀"}

                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                {/* review textarea */}

                                                <div className="col-12">
                                                  <label htmlFor="">Review </label>
                                                  <ul type="1" className="verti-timeline list-unstyled notes-box ms-4 mt-4" style={{ minHeight: '100px' }}>
                                                    {
                                                      candidate.map((candidat, i) => {
                                                        return <li key={i} className="event-list position-relative ">
                                                          <div className="event-timeline-dot position-relative">
                                                            <div className="d-flex justify-content-center align-items-center text-uppercase fw-bold text-blue rounded-circle bg-info bg-soft"
                                                              style={{
                                                                position: 'absolute',
                                                                top: '0',
                                                                left: '-5%',
                                                                width: '55px',
                                                                height: '55px',
                                                                fontSize: '20px'
                                                              }}
                                                            >
                                                              {
                                                                candidat.users_name.split(' ').slice(0, 2).map((c, i) => <span key={i}>{c[0]}</span>)
                                                              }
                                                            </div>
                                                          </div>
                                                          <div className="d-flex ">
                                                            <div className="flex-grow-1 ms-4 view-field p-2 position-relative">
                                                              <div className='d-flex justify-content-between py-2 rounded ps-4'>
                                                                <div>
                                                                  <p className="text-muted ">
                                                                    <h6 className='d-inline me-2'>By {candidat.users_name}

                                                                      <span className="ps-2">
                                                                        {
                                                                          moment(candidat.updated_at).format("DD-MM-YYYY hh:mm:s")
                                                                        }
                                                                      </span>
                                                                    </h6>
                                                                  </p>
                                                                  {/* <i className="bi bi-triangle-fill fs-5"
                                                                    style={{
                                                                      position: 'absolute'
                                                                    }}
                                                                  ></i> */}
                                                                  <p className='mb-1'>{candidat.reviews}</p>
                                                                </div>

                                                                <p></p>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </li>
                                                      })
                                                    }
                                                  </ul>

                                                </div>
                                              </Row>
                                            </CardBody>
                                          </Card>
                                        </div>

                                      </div>
                                    </div>
                                  </Row>

                                  : <h5 className="bg-danger bg-soft p-3 text-danger">Candidate Not Found</h5>
                              )
                          }
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment >
  );
};


export default withTranslation()(Dashboard2);
