export const allIndustries = [
    "Commodity Chemicals",
    "Specialty Chemicals",
    "Carbon Black",
    "Dyes And Pigments",
    "Explosives",
    "Petrochemicals",
    "Printing Inks",
    "Trading - Chemicals",
    "Industrial Gases",
    "Fertilizers",
    "Pesticides & Agrochemicals",
    "Cement & Cement Products",
    "Other Construction Materials",
    "Ferro & Silica Manganese",
    "Pig Iron",
    "Sponge Iron",
    "Iron & Steel",
    "Aluminium",
    "Copper",
    "Zinc",
    "Precious Metals",
    "Diversified Metals",
    "Industrial Minerals",
    "Trading - Metals",
    "Trading - Minerals",
    "Paper & Paper Products",
    "Forest Products",
    "Jute & Jute Products",
    "Passenger Cars & Utility Vehicles",
    "2 / 3 Wheelers",
    "Auto Dealer",
    "Auto Components & Equipments",
    "Tyres & Rubber Products",
    "Trading - Auto Components",
    "Cycles",
    "Consumer Electronics",

    "Furniture, Home Furnishing",
    "Ceramics",
    "Granites & Marbles",
    "Gems, Jewellery And Watches",
    "Glass - Consumer",
    "Household Appliances",
    "Houseware",
    "Leather And Leather Products",
    "Leisure Products",
    "Plastic Products - Consumer",

    "Plywood Boards / Laminates",
    "Sanitary Ware",
    "Paints",
    "Diversified consumer products",
    "Footwear",
    "Garments & Apparels",
    "Other Textile Products",
    "Trading - Textile Products",
    "Advertising & Media Agencies",
    "Electronic Media",
    "Web based media and service",
    "Print Media",
    "Film Production, Distribution & Exhibition",
    "Digital Entertainment",
    "Media & Entertainment",
    "TV Broadcasting & Software Production",
    "Printing & Publication",
    "Residential, Commercial Projects",
    "Real Estate related services",
    "Real Estate Investment Trusts(REITs)",
    "Hotels & Resorts",
    "Restaurants",
    "Amusement Parks / Other Recreation",
    "Wellness",
    "Tour, Travel Related Services",
    "Education",
    "E-Learning",
    "Food Storage Facilities",
    "Other Consumer Services",
    "Speciality Retail",
    "Pharmacy Retail",
    "Diversified Retail",
    "E - Retail / E - Commerce",
    "Internet & Catalogue Retail",
    "Distributors",
    "Gas Transmission / Marketing",
    "LPG / CNG / PNG / LNG Supplier",
    "Trading - Gas",
    "Oil Exploration & Production",
    "Offshore Support Solution Drilling",
    "Oil Storage & Transportation",
    "Oil Equipment & Services",
    "Refineries & Marketing",
    "Lubricants",
    "Coal",
    "Trading - Coal",
    "Edible Oil",
    "Sugar",
    "Tea & Coffee",
    "Other Agricultural Products",
    "Breweries & Distilleries",
    "Other Beverages",
    "Cigarettes & Tobacco Products",
    "Animal Feed",
    "Dairy Products",
    "Other Food",
    "Products",
    "Packaged Foods",
    "Seafood",
    "Meat Products including Poultry",
    "Personal Care",
    "Household Products",
    "Stationary",
    "Diversified FMCG",

    "Financial Institution",
    "Housing Finance Company",
    "Investment Company",
    "Non Banking Financial Company(NBFC)",
    "Other Financial Services",
    "Holding Company",
    "Microfinance Institutions",
    "Securitisation",
    "Public Sector Bank",
    "Private Sector Bank",
    "Other Bank",
    "Asset Management Company",
    "Depositories, Clearing Houses and Other Intermediaries",
    "Financial Products Distributor",
    "Ratings",
    "Exchange and Data Platform",
    "Stockbroking & Allied",
    "Other Capital Market related Services",
    "General Insurance",
    "Life Insurance",
    "Other Insurance Companies",
    "Insurance Distributors",
    "Financial Technology(Fintech)",
    "Pharmaceuticals",
    "Biotechnology",
    "Medical Equipment & Supplies",
    "Hospital",
    "Healthcare Service Provider",
    "Healthcare Research, Analytics & Technology",
    "Civil Construction",
    "Aerospace & Defense",
    "Tractors",
    "Commercial",
    "Vehicles",
    "Construction Vehicles",
    "Dealers– Commercial Vehicles, Tractors",
    "Construction Vehicles",
    "Heavy Electrical Equipment",
    "Other Electrical Equipment",
    "Railway Wagons",
    "Ship Building & Allied Services",
    "Industrial Products",
    "Cables - Electricals",
    "Castings & Forgings",
    "Packaging",
    "Plastic Products - Industrial",
    "Rubber",
    "Other Industrial Products",
    "Glass - Industrial",
    "Aluminium, Copper & Zinc Products",
    "Iron & Steel Products",
    "Abrasives & Bearings",

    "Compressors, Pumps & Diesel Engines",
    "Electrodes & Refractories",
    "Computers - Software & Consulting",
    "Software Products",
    "IT Enabled Services",
    "Computers Hardware & Equipments",
    "Dredging",
    "Airline",
    "Logistics Solution Provider",
    "Railways",
    "Road Transport",
    "Shipping",
    "Transport Related Services",
    "Airport & Airport services",
    "Port & Port services",
    "Road Assets–Toll, Annuity, Hybrid - Annuity",
    "Trading & Distributors",
    "Consulting Services",
    "Data Processing Services",
    "Diversified Commercial Services",
    "Business Process Outsourcing(BPO) / Knowledge Process Outsourcing(KPO)",
    "Urban Local Bodies",
    "Development Authority",
    "Telecom - Cellular & Fixed line services",
    "Telecom - Infrastructure",
    "Other Telecom Services",
    "Telecom - Equipment & Accessories",
    "Power Generation",
    "Integrated Power Utilities",
    "Power Trading",
    "Power - Transmission",
    "Power Distribution",
    "Water Supply & Management",
    "Waste Management",
    "Emergency Services",
    "Multi Utilities",
    "Other Utilities",
    "Diversified",
]