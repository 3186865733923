import React, { useEffect, useState } from "react";

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, Label } from "reactstrap";

//redux
import { Link, useNavigate } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// import images
import profile from "assets/images/profile-img.png";
import logo from "assets/images/logo.svg";

// import css
import '../../assets/css/Login/Login.css';
import axios from "axios";
import Loader from "pages/Loader";

const Login = () => {

  const navigate = useNavigate()

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [isLoading, setLoading] = useState(false)
  const [error2, setError2] = useState(false)

  const [show_pass, setShowPass] = useState(true)

  //meta title
  document.title = "Login | JobFitMeter";

  useEffect(() => {
    if (localStorage.getItem("adminName") && localStorage.getItem("adminId")) {
      navigate("/")
    }
  }, [])


  const handleLogin = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()

    formData.append("email", email)
    formData.append("password", password)
    formData.append("type", "admin")

    try {
      const response = await axios.post('https://api.jobfitmeter.com/api/admin_login', formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        }
      })

      if (response.data.success == true) {
        localStorage.setItem("adminId", response.data.data[0].id)
        localStorage.setItem("adminName", response.data.data[0].company_name)

        if (localStorage.getItem("junkLink")) {
          navigate(`/candidate/${localStorage.getItem("junkLink")}`)
        } else {
          navigate("/")
        }

      } else {
        setError2(true)
      }
      setLoading(false)

    } catch (err) {
      setError2(true)
      console.log(err)
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="login-page">
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-info bg-soft">
                    <Row>
                      <Col xs={8}>
                        <div className="p-4">
                          <h5 className="text-info">Welcome Back ! </h5>
                          <p className="">Sign in to continue to Job fit Meter.</p>
                        </div>
                      </Col>
                      <Col className="col-4 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/" className="logo-light-element">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="px-2 py-4">
                      <Form
                        className="form-horizontal"
                        onSubmit={handleLogin}
                      >
                        {error2 ? (
                          <Alert color="danger">
                            Username and password are invalid. Please enter
                            correct username and password
                          </Alert>
                        ) : null}

                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            value={email}
                            type="email"
                            onChange={e => {
                              setEmail(e.target.value)
                              setError2(false)
                            }}
                          />
                        </div>

                        <div className="mb-3 position-relative">
                          <Label className="form-label">Password</Label>
                          <Input
                            name="password"
                            type={show_pass ? "password" : "text"}
                            placeholder="Enter Password"
                            value={password}
                            onChange={e => {
                              setPassword(e.target.value)
                              setError2(false)
                            }}
                          />

                          <div className="bd-eye-pass" onClick={() => setShowPass(!show_pass)}>
                            <i className={show_pass ? "fa fa-eye" : "fa fa-eye-slash"}></i>
                          </div>
                        </div>

                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customControlInline"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customControlInline"
                          >
                            Remember me
                          </label>
                        </div>

                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-info btn-block"
                            type="submit"
                          >
                            Log In
                          </button>
                        </div>

                        {/* <div className="mt-4 text-center">
                          <Link to='/forgot-password" className="text-muted">
                            <i className="mdi mdi-lock me-1" />
                            Forgot your password?
                          </Link>
                        </div> */}

                        <div className="mt-4 text-center">
                          <p className="mb-0">
                            Don&#39;t have an account ?{" "}
                            <a href="https://www.jobfitmeter.com/register" className="fw-medium text-info">
                              {" "}
                              Register now{" "}
                            </a>{" "}
                          </p>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>

              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

// Login.propTypes = {
//   history: PropTypes.object,
// };
