import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard2
import Dashboard2 from "../pages/Dashboard2/index"
import AddNewCandidate from "pages/Dashboard2/AddNewCandidate"
import ViewCandidates from "pages/Dashboard2/ViewCandidates"
import EditCandidates from "pages/Dashboard2/EditCandidates"
import AddReview from "pages/Dashboard2/AddReview"
import Login2 from "pages/Authentication/Login2"

const authProtectedRoutes = [

  // https://api.jobfitmeter.com/api/

  { path: "/", component: <Dashboard2 /> },
  { path: "/add-new-candidate", component: <AddNewCandidate /> },
  { path: "/candidate/:junklink", component: <AddReview /> },
  { path: "/view-candidates", component: <ViewCandidates /> },
  { path: "/edit-candidates/:candidate_id", component: <EditCandidates /> },

  // //profile
  { path: "/profile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: < Navigate to="/" />,
  },
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login2 /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
]

export { authProtectedRoutes, publicRoutes }
