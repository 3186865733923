import React from "react";
import { Navigate, useParams } from "react-router-dom";

const Authmiddleware = (props) => {

  const { junklink } = useParams()

  if (!localStorage.getItem("adminName") && !localStorage.getItem("adminId")) {
    if (junklink) {
      localStorage.setItem("junkLink", junklink)
    }

    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }
  return (<React.Fragment>
    {props.children}
  </React.Fragment>);
};

export default Authmiddleware;
