import React, { useMemo, Fragment, useEffect, useState } from "react"
import {
    useTable,
    useGlobalFilter,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
} from "react-table"
import { Table, Row, Col, Button, Container, CardBody, Card } from "reactstrap"
//redux
import SearchFilter from "components/Common/SearchFilter"
import Breadcrumb from "components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import axios from "axios"
import Loader from "pages/Loader"

const ViewCandidates = () => {

    const [isLoading, setIsLoading] = useState(false)
    const [allCandidates, setAllCandidates] = useState([])

    useEffect(() => {
        window.scrollTo(0, 0)
        const fetchData = async () => {
            setIsLoading(true)
            try {
                const formData = new FormData()
                formData.append("company_id", localStorage.getItem("adminId"))

                const response = await axios.post('https://api.jobfitmeter.com/api/show_candidates', formData, {
                    header: {
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                    }
                });

                setAllCandidates(response.data.data.candidate)
                setIsLoading(false)
            }
            catch (error) {
                console.log(error)
            }
        }
        fetchData()
    }, [])

    document.title = "Candidates | JobFitMeter"

    const onCandidateDelete = (candidate_id, company_id) => {
        swal({
            title: "Are you sure?",
            text: "You really want to delete this record!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {

                    setIsLoading(true)
                    const formData = new FormData()
                    formData.append("candidate_id", candidate_id)
                    formData.append("company_id", company_id)

                    axios.post('https://api.jobfitmeter.com/api/delete_candidates', formData, {
                        header: {
                            Accept: "application/json",
                            "Content-Type": "multipart/form-data",
                        }
                    })
                        .then((response) => {
                            swal("Candidate has been deleted successfully!", {
                                icon: "success",
                            })

                            setAllCandidates(response.data.data.candidate)
                            setIsLoading(false)
                        })
                        .catch((error) => {
                            console.log(error)
                            setIsLoading(false)
                            swal("Something Went Wrong", "", "error")
                        })
                }
            });
    }

    const columns = useMemo(
        () => [
            {
                Header: "ID",
                accessor: "id",
                filterable: true,
                disableFilters: true,
                className: "col-1",
                Cell: cellProps => {
                    return (
                        <span>{cellProps.row.index + 1}</span>
                    )
                },
            },
            {
                Header: "Candidate Name",
                accessor: "candidate_name",
                disableFilters: true,
                filterable: true,
            },
            {
                Header: "Aadhar Number",
                accessor: "aadhar_no",
                disableFilters: true,
                filterable: true,
            },
            {
                Header: "Industry",
                accessor: "industry",
                disableFilters: true,
                filterable: true,
            },
            {
                Header: "Date",
                accessor: "created_at",
                disableFilters: true,
                filterable: true,
                Cell: cellProps => {
                    return (
                        <span>{new Date(cellProps.data[cellProps.row.index].created_at).toLocaleDateString('en-GB')}</span>
                    )
                },
            },
            {
                Header: "Action",
                disableFilters: true,
                accessor: "view",
                className: "col-2",
                Cell: cellProps => {

                    const candidate_id = cellProps.data[cellProps.row.index].id
                    const company_id = cellProps.data[cellProps.row.index].company_id
                    return (
                        <div>
                            <button
                                onClick={() => {
                                    navigator.clipboard.writeText("https://app.jobfitmeter.com/candidate/" + cellProps.row.original.generate_link);
                                }
                                }
                                className="btn btn-secondary btn-sm mr-10"
                                title="Copy Candidate Link"
                            >
                                <i className="fas fa-copy"></i>
                            </button>
                            <Link to={`/edit-candidates/${candidate_id}`}
                                className="btn btn-outline-secondary btn-sm"
                            >
                                <i className="fas fa-pen"></i>
                            </Link>
                            <button
                                onClick={() =>
                                    onCandidateDelete(candidate_id, company_id)}
                                className="btn btn-danger btn-sm ml-10"
                            >
                                <i className="fas fa-trash-alt"></i>
                            </button>
                        </div>
                    )
                },
            },
        ],
        []
    )

    const data = useMemo(() => allCandidates, [allCandidates])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: 10
            },
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination
    )

    const generateSortingIndicator = column => {
        return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
    }

    const onChangeInSelect = event => {
        setPageSize(Number(event.target.value))
    }

    return (
        <div>
            {
                isLoading && <Loader />
            }
            <div className="page-content">
                <Container fluid >
                    <Breadcrumb
                        title={"Dashboard"}
                        breadcrumbItem={"All Candidates"}
                    />
                    <Row>
                        <Card className="px-0 mx-0">
                            <CardBody >

                                <Row className="mb-2 justify-space-between flex-md-row flex-column-reverse">
                                    <Col md={2}>
                                        <select
                                            className="form-select d-md-block d-none"
                                            value={pageSize}
                                            onChange={onChangeInSelect}
                                        >
                                            {[10, 20, 30, 40, 50].map(pageSize => (
                                                <option key={pageSize} value={pageSize}>
                                                    Show {pageSize}
                                                </option>
                                            ))}
                                        </select>

                                        <div className="d-md-none mt-3">
                                            <SearchFilter
                                                preGlobalFilteredRows={preGlobalFilteredRows}
                                                globalFilter={state.globalFilter}
                                                setGlobalFilter={setGlobalFilter}
                                            />
                                        </div>
                                    </Col>
                                    <div className="d-flex col-md-8 justify-content-md-end justify-content-between align-items-start ">
                                        <div className="d-md-block d-none">
                                            <SearchFilter
                                                preGlobalFilteredRows={preGlobalFilteredRows}
                                                globalFilter={state.globalFilter}
                                                setGlobalFilter={setGlobalFilter}
                                            />
                                        </div>

                                        <select
                                            className="form-select d-md-none w-50"
                                            value={pageSize}
                                            onChange={onChangeInSelect}
                                        >
                                            {[10, 20, 30, 40, 50].map(pageSize => (
                                                <option key={pageSize} value={pageSize}>
                                                    Show {pageSize}
                                                </option>
                                            ))}
                                        </select>

                                        <Link to="/" className="btn btn-secondary bg-dark-blue ms-4">
                                            <i className="fas fa-arrow-left me-3"></i>
                                            Back
                                        </Link>
                                    </div>
                                </Row>

                                <div className="table-responsive striped-table">
                                    <Table
                                        bordered
                                        {...getTableProps()}
                                        className="w-800 responsive-table "
                                        style={{ verticalAlign: "middle" }}
                                    >
                                        <thead className="table-light table-nowrap">
                                            {headerGroups.map(headerGroup => (
                                                <tr
                                                    key={headerGroup.id}
                                                    {...headerGroup.getHeaderGroupProps()}
                                                >
                                                    {headerGroup.headers.map(column => (
                                                        <th key={column.id} className={column.className}>
                                                            <div {...column.getSortByToggleProps()}>
                                                                {column.render("Header")}
                                                                {generateSortingIndicator(column)}
                                                            </div>
                                                            {/* <Filter column={column} /> */}
                                                        </th>
                                                    ))}
                                                </tr>
                                            ))}
                                        </thead>

                                        <tbody className="row-hover" {...getTableBodyProps()}>
                                            {page.map(row => {
                                                prepareRow(row)
                                                return (
                                                    <Fragment key={row.getRowProps().key}>
                                                        <tr>
                                                            {row.cells.map(cell => {
                                                                return (
                                                                    <td key={cell.id} data-label={cell.column.Header} {...cell.getCellProps()}>
                                                                        {cell.render("Cell")}
                                                                    </td>
                                                                )
                                                            })}
                                                        </tr>

                                                    </Fragment>
                                                )
                                            })}

                                            {
                                                page.length === 0 && <tr>
                                                    <td colSpan={6} className="text-center">No Candidate Found</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>

                                <Row className="justify-content-md-end justify-content-center align-items-center mt-2">
                                    <Col className="col-md-auto">
                                        <div className="d-flex gap-1">
                                            <Button
                                                className="btn-blue"
                                                color="primary"
                                                onClick={() => gotoPage(0)}
                                                disabled={!canPreviousPage}
                                            >
                                                {"<<"}
                                            </Button>
                                            <Button
                                                className="btn-blue"
                                                onClick={previousPage}
                                                disabled={!canPreviousPage}
                                            >
                                                {"<"}
                                            </Button>
                                        </div>
                                    </Col>
                                    <Col className="col-md-auto d-none d-md-block">
                                        Page{" "}
                                        <strong>
                                            {pageIndex + 1} of {pageOptions.length}
                                        </strong>
                                    </Col>

                                    <Col className="col-md-auto">
                                        <div className="d-flex gap-1">
                                            <Button
                                                className="btn-blue"
                                                onClick={nextPage}
                                                disabled={!canNextPage}
                                            >
                                                {">"}
                                            </Button>
                                            <Button
                                                className="btn-blue"
                                                onClick={() => gotoPage(pageCount - 1)}
                                                disabled={!canNextPage}
                                            >
                                                {">>"}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default ViewCandidates
